import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useState } from 'react';
import EyeCheck_Logo from '../../assets/images/exp/rih.png';
import Website_Logo from '../../assets/images/exp/sph.jpeg';
import Piel_Logo from '../../assets/images/exp/ams.png';
import Myo_Logo from '../../assets/images/exp/beacon.png';
import RMS_Logo from '../../assets/images/exp/brown.png';

import Phantom_Desc from '../../assets/images/exp/pm_code.png';
import EyeCheck_Desc from '../../assets/images/exp/EyeCheck_2.png';
import Website_Desc from '../../assets/images/exp/Ponto_map.png';
import Piel_Desc from '../../assets/images/exp/piel_2.png';
import Myo_Desc from '../../assets/images/exp/setup.jpeg';
import RMS_Desc from '../../assets/images/exp/alexa.jpg';


import Piel_2 from '../../assets/images/exp/piel_3.png';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import JS from '../../assets/skill-icons/js.png';

// import CSS3 from '../../assets/skill-icons/css3.png';
import SCSS from '../../assets/skill-icons/scss.png';

import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faUpRightFromSquare, faBook} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faApple } from '@fortawesome/free-brands-svg-icons';
// import Modal from './Modal';


const Experience = () => {
    const [letterClass] = useState('text-animate3');
    const [isOpenEyeCheck, setIsOpenEyeCheck] = useState(false);
    const [isOpenWebsite, setIsOpenWebsite] = useState(false);
    const [isOpenPiel, setIsOpenPiel] = useState(false);
    const [isOpenMyo, setIsOpenMyo] = useState(false);
    const [isOpenRMS, setIsOpenRMS] = useState(false);

    return (
        <div className="container experience-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                    strArray={['E','x','p','e','r','i','e','n','c','e']}
                    index={15}
                    letterClass={letterClass}
                    >
                    </AnimatedLetters>
                </h1>
                <div className="grid-container">
                    <div className="grid-item">
                        <img src={EyeCheck_Logo} alt="EyeCheck" onClick={() => setIsOpenEyeCheck(true)}></img>
                        <div>Artificial Intelligence <br></br> Radiology Lab</div>
                    </div>
                    <Modal open={isOpenEyeCheck}
                    onClose={() => setIsOpenEyeCheck(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Artificial Intelligence <br></br> Radiology Lab</h1>
                        <div>
                            The Radiology Artificial Intelligence Lab at the Rhode Island Hospital works on a wide range of problems in clinical data science.
                            Their goal is to develop machine learning techniques based on imaging to assist clinical decision making, avoid medical errors and
                            ultimately improve patient health. The Radiology Artificial Intelligence Lab works closely with the AI labs at Johns Hopkins University and
                            University of Pennsylvania to develop algorithms using data across multiple institutions.
                        </div>
                        <br></br>
                        <div align="left">
                        <b>Abstract&nbsp;</b>
                        <ul>
                        <li><u>Kumar S</u>, Sollee J, Choi U, Lin T, Bai H, Jiao Z.
                        Pediatric COVID-19 Diagnosis using Artificial Intelligence based on Chest
                        Radiographs. Society of Pediatric Radiology 2023 Meeting</li>
                        </ul>
                        </div>
                        <br></br>
                        <div align="left">
                        <b>Poster&nbsp;</b>
                        <ul>
                        <li><u>Kumar S</u>, Jian P, Wu J, Bai H, Jiao Z.
                        Post-Treatment Glioblastoma Outcome Prediction via SuperLearning and
                        Multimodal Deep Learning. Warren Alpert Medical School Academic Symposium 2022</li>
                        </ul>
                        </div>
                        <br></br>
                        <div align="left">
                        <b>Awards&nbsp;</b>
                        <ul>
                        <li>2022 AMS Academic Symposium- Clinical Medicine Winner</li>
                        <li>2022 Radiological Society of North America Medical Student Grant</li>
                        <li>2022 Alpert Medical School Summer Assistantship Award</li>
                        </ul>
                        </div>
                        <br></br>
                        <div className="actionsContainer">
                            <a href="https://sites.brown.edu/airadiology/" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Website &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                        <button
                            className="cancelBtn"
                            onClick={() => setIsOpenEyeCheck(false)}
                        >
                            Close
                        </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Piel_Logo} alt="Piel" onClick={() => setIsOpenPiel(false)}></img>
                        <div>Warren Alpert Medical School <br></br> of Brown University</div>
                    </div>
                    <Modal open={isOpenPiel}
                    onClose={() => setIsOpenPiel(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Warren Alpert Medical School <br></br> of Brown University</h1>
                        <img src={Piel_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Piel is a free iPhone app that uses artifical intelligence to detect skin cancer
                            in minority patients through camera photos. Existing skin cancer detection algorithms
                            frequently underperform on minority patients due to a lack of proper
                            representation in datasets. Piel is trained on patients across all skin tones using
                            a VGG-based Convolutional Neural Network. Piel has over 100 downloads in the App Store.
                        </div>
                        <br></br>
                        <br></br>
                        <b>Language:&nbsp;</b>Swift
                        <br></br>
                        <b>Award:&nbsp;</b>First Place (Biomedical Entrepreneurship Network Hackathon)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Arnav Kumar, Swata Alagar
                        <div className="actionsContainer">
                        <a href="https://apps.apple.com/tt/app/piel/id1628353345" target="_blank" rel="noreferrer">
                            <button className="videoBtn">
                                App Store &nbsp;
                                <FontAwesomeIcon icon={faApple} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                            <a href="https://sites.google.com/brown.edu/piel/home" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Website &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenPiel(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Website_Logo} alt="Ponto" onClick={() => setIsOpenWebsite(false)}></img>
                        <div>Brown University <br></br> School of Public Health</div>
                    </div>
                    <Modal open={isOpenWebsite}
                    onClose={() => setIsOpenWebsite(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Brown University <br></br> School of Public Health</h1>
                        <img src={Website_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Ponto is a web-based search platform that connects patients to culturally-competent
                            providers. Physician clinics register for Ponto and build their profiles to be searched
                            by potential patients. Patients are able to enter their chief complaint, location,
                            and cultural identity to help find providers who best fit their criteria.
                            Ponto is currently being piloted for the Hispanic community in Rhode Island.
                        </div>
                        <br></br>
                        <b>Awards:&nbsp;</b>First Place & NEMIC Award (Brown University Hatch Health Venture Prize),
                        <br></br>
                        Third Place (Biomedical Entrepreneurship Network Venture Prize)
                        <br></br>
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Cat Nguyen, Daniel Chan
                        <div className="actionsContainer">
                            <a href="https://pitchbook.com/profiles/company/517082-14#funding" target="_blank" rel="noreferrer">
                                <button className="codeBtn">
                                    PitchBook &nbsp;
                                    <FontAwesomeIcon icon={faBook} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenWebsite(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Myo_Logo} alt="Myoelectric Prosthetic" onClick={() => setIsOpenMyo(false)}></img>
                        <div>Beacon Biosignals</div>
                    </div>
                    <Modal open={isOpenMyo}
                    onClose={() => setIsOpenMyo(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Beacon Biosignals</h1>
                        <img src={Myo_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Myoelectric prosthetics are developed as a cheaper alternative and more efficient
                            alternative to traditional prosthetics for amputees and patients with paralysis. They
                            leverage EMG to provide an impulse to the prosthetic in order to induce a contraction. The EMG
                            signal is filtered and amplified by a circuit before passing through an Arduino, which activates the motor
                            and induces a contraction within the prosthetic.
                        </div>
                        <br></br>
                        <b>Language:&nbsp;</b>Arduino
                        <br></br>
                        <b>Award:&nbsp;</b>Best Hardware Hack & Wolfram Award (Hack@Brown 2021)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Cat Nguyen, Daniel Chan
                        <div className="actionsContainer">
                            <a href="https://www.youtube.com/watch?v=4iIKbNMxO1Q" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Video Demo &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                        <a href="https://devpost.com/software/remote-myoelectric-prosthetics" target="_blank" rel="noreferrer">
                            <button className="codeBtn">
                                Devpost &nbsp;
                                <FontAwesomeIcon icon={faGithub} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                        <button
                            className="cancelBtn"
                            onClick={() => setIsOpenMyo(false)}
                        >
                            Close
                        </button>
                        </div>
                    </Modal>

                    <div className="grid-item">
                        <img src={RMS_Logo} alt="RMS" onClick={() => setIsOpenRMS(false)}></img>
                        <div>Brown University</div>
                    </div>
                    <Modal open={isOpenRMS}
                        onClose={() => setIsOpenRMS(false)}
                        center
                        classNames={{
                            overlay: 'customOverlay',
                            modal: 'customModal',
                        }}>
                        <h1 className="heading">Brown University</h1>
                        <img src={RMS_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Perhuman is an Amazon Alexa module that helps families and peers
                            provide better verbal feedback to those suffering from mental illness.
                            Inspired by interactive, choose-your-own-adventure stories,
                            PerHuman provides real life situations to users in which someone might need verbal support.
                            After a user responds to Alexa, PerHuman analyzes the users’ replies for negative keywords and
                            phrases and offer guidance for a better response.
                        </div>
                        <br></br>
                        <br></br>
                        <b>Language:&nbsp;</b>Javascript
                        <br></br>
                        <b>Award:&nbsp;</b>Best Tech Prize (HackHealth 2019)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Daniel Chan, May Gao, Jenny He, Michelle Zhu
                        <div className="actionsContainer">
                            <a href="https://www.browndailyherald.com/2019/09/16/hack-health-event-spotlights-mental-health-fosters-student-innovation/" target="_blank" rel="noreferrer">
                                <button className="codeBtn">
                                    Article &nbsp;
                                    <FontAwesomeIcon icon={faBook} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenRMS(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Experience;

import { useState } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import Phantom_Logo from '../../assets/images/projects/pm_icon.png';
import EyeCheck_Logo from '../../assets/images/projects/EyeCheck_1.png';
import Website_Logo from '../../assets/images/projects/Ponto_alt.png';
import Piel_Logo from '../../assets/images/projects/piel_1.png';
import Myo_Logo from '../../assets/images/projects/myo.png';
import RMS_Logo from '../../assets/images/projects/perhuman.png';

import Phantom_Desc from '../../assets/images/projects/pm_code.png';
import EyeCheck_Desc from '../../assets/images/projects/EyeCheck_2.png';
import Website_Desc from '../../assets/images/projects/Ponto_map.png';
import Piel_Desc from '../../assets/images/projects/piel_2.png';
import Myo_Desc from '../../assets/images/projects/setup.jpeg';
import RMS_Desc from '../../assets/images/projects/alexa.jpg';


import Piel_2 from '../../assets/images/projects/piel_3.png';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import JS from '../../assets/skill-icons/js.png';

// import CSS3 from '../../assets/skill-icons/css3.png';
import SCSS from '../../assets/skill-icons/scss.png';

import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faUpRightFromSquare, faBook} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faApple } from '@fortawesome/free-brands-svg-icons';
// import Modal from './Modal';


const Projects = () => {
    const [letterClass] = useState('text-animate5');
    const [isOpenPhantom, setIsOpenPhantom] = useState(false);
    const [isOpenEyeCheck, setIsOpenEyeCheck] = useState(false);
    const [isOpenWebsite, setIsOpenWebsite] = useState(false);
    const [isOpenPiel, setIsOpenPiel] = useState(false);
    const [isOpenMyo, setIsOpenMyo] = useState(false);
    const [isOpenRMS, setIsOpenRMS] = useState(false);


    return (
        <div className="container projects-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                    strArray={['P', 'r', 'o', 'j','e','c','t','s']}
                    index={15}
                    letterClass={letterClass}
                    >
                    </AnimatedLetters>
                </h1>

                <div className="grid-container">
                <div className="grid-item">
                        <img src={Phantom_Logo} alt="Phantom" onClick={() => setIsOpenPhantom(true)}></img>
                        <div>Phantom Mirror</div>
                </div>
                <Modal open={isOpenPhantom}
                    onClose={() => setIsOpenPhantom(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Phantom Mirror</h1>
                        <img src={Phantom_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div>
                            Phantom Mirror is an augmented reality lens designed to help
                            manage phantom limb pain in amputees. It leverages augmented reality
                            to reflect a patient's existing limb to subconciously trick the patient's
                            brain into believing the lost limb is there. This mimics the existing mirror box
                            therapy at no cost to the user. Phantom Mirror is built in Lens Studio and
                            is publicly available on SnapChat with over 4.9 million uses and 184K shares worldwide.
                        </div>
                        <br></br>
                        <b>Language:&nbsp;</b>Javascript
                        <br></br>
                        <b>Award:&nbsp;</b>MVF Prize (Snap AR Lensathon 2022)
                        <div className="actionsContainer">
                            <a href="https://lens.snapchat.com/1162740a2917488e8ea3b79af95deedd?sender_web_id=3781f128-62c2-4612-80e8-1cd0acdecafc&device_type=desktop&is_copy_url=true" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Link &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <a href="https://www.youtube.com/watch?v=Qcl04-xa9ME" target="_blank" rel="noreferrer">
                                <button className="videoBtn">
                                    Video &nbsp;
                                    <FontAwesomeIcon icon={faPlay} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <a href="https://devpost.com/software/phantom-mirror" target="_blank" rel="noreferrer">
                                <button className="codeBtn">
                                    Devpost &nbsp;
                                    <FontAwesomeIcon icon={faGithub} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenPhantom(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={EyeCheck_Logo} alt="EyeCheck" onClick={() => setIsOpenEyeCheck(true)}></img>
                        <div>EyeCheck</div>
                    </div>
                    <Modal open={isOpenEyeCheck}
                    onClose={() => setIsOpenEyeCheck(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">EyeCheck</h1>
                        <img src={EyeCheck_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            EyeCheck is a free mobile app built for iPhone and Android that guides medical students
                            through the ophthalmology intake process.
                            It is specifically optimized to aid in low-resource Spanish-speaking clinics.
                            EyeCheck has over 100 downloads across iOS and Android.
                        </div>
                        <br></br>
                        <div>
                            EyeCheck guides users through patient encounters via its 4 main sections: 1) History, 2) Exam,
                            3) Pharmacy, 4) Glossary.
                        <br></br>
                        </div>
                        <br></br>
                        <b>Language:&nbsp;</b>React Native
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Arnav Kumar, Anagha Lokhande, Jamie Schaefer
                        <br></br>
                        <b>Funding:&nbsp;</b>Brown Medical Alumni Association
                        <div className="actionsContainer">
                        <a href="https://apps.apple.com/tt/app/eye-check/id1643416389" target="_blank" rel="noreferrer">
                            <button className="videoBtn">
                                App Store &nbsp;
                                <FontAwesomeIcon icon={faApple} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                            <a href="https://sites.google.com/brown.edu/eyecheck/home" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Website &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                        <a href="https://github.com/amkumar645/EyeCheck" target="_blank" rel="noreferrer">
                            <button className="codeBtn">
                                Code &nbsp;
                                <FontAwesomeIcon icon={faGithub} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                        <button
                            className="cancelBtn"
                            onClick={() => setIsOpenEyeCheck(false)}
                        >
                            Close
                        </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Piel_Logo} alt="Piel" onClick={() => setIsOpenPiel(true)}></img>
                        <div>Piel</div>
                    </div>
                    <Modal open={isOpenPiel}
                    onClose={() => setIsOpenPiel(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Piel</h1>
                        <img src={Piel_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Piel is a free iPhone app that uses artifical intelligence to detect skin cancer
                            in minority patients through camera photos. Existing skin cancer detection algorithms
                            frequently underperform on minority patients due to a lack of proper
                            representation in datasets. Piel is trained on patients across all skin tones using
                            a VGG-based Convolutional Neural Network. Piel has over 100 downloads in the App Store.
                        </div>
                        <br></br>
                        <br></br>
                        <b>Language:&nbsp;</b>Swift
                        <br></br>
                        <b>Award:&nbsp;</b>First Place (Biomedical Entrepreneurship Network Hackathon)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Arnav Kumar, Swata Alagar
                        <div className="actionsContainer">
                        <a href="https://apps.apple.com/tt/app/piel/id1628353345" target="_blank" rel="noreferrer">
                            <button className="videoBtn">
                                App Store &nbsp;
                                <FontAwesomeIcon icon={faApple} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                            <a href="https://sites.google.com/brown.edu/piel/home" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Website &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenPiel(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Website_Logo} alt="Ponto" onClick={() => setIsOpenWebsite(true)}></img>
                        <div>Ponto</div>
                    </div>
                    <Modal open={isOpenWebsite}
                    onClose={() => setIsOpenWebsite(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Ponto</h1>
                        <img src={Website_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Ponto is a web-based search platform that connects patients to culturally-competent
                            providers. Physician clinics register for Ponto and build their profiles to be searched
                            by potential patients. Patients are able to enter their chief complaint, location,
                            and cultural identity to help find providers who best fit their criteria.
                            Ponto is currently being piloted for the Hispanic community in Rhode Island.
                        </div>
                        <br></br>
                        <b>Awards:&nbsp;</b>First Place & NEMIC Award (Brown University Hatch Health Venture Prize),
                        <br></br>
                        Third Place (Biomedical Entrepreneurship Network Venture Prize)
                        <br></br>
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Cat Nguyen, Daniel Chan
                        <div className="actionsContainer">
                            <a href="https://pitchbook.com/profiles/company/517082-14#funding" target="_blank" rel="noreferrer">
                                <button className="codeBtn">
                                    PitchBook &nbsp;
                                    <FontAwesomeIcon icon={faBook} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenWebsite(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="grid-item">
                        <img src={Myo_Logo} alt="Myoelectric Prosthetic" onClick={() => setIsOpenMyo(true)}></img>
                        <div>Myoelectric Prosthetic</div>
                    </div>
                    <Modal open={isOpenMyo}
                    onClose={() => setIsOpenMyo(false)}
                    center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                    }}>
                        <h1 className="heading">Myoelectric Prosthetic</h1>
                        <img src={Myo_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Myoelectric prosthetics are developed as a cheaper alternative and more efficient
                            alternative to traditional prosthetics for amputees and patients with paralysis. They
                            leverage EMG to provide an impulse to the prosthetic in order to induce a contraction. The EMG
                            signal is filtered and amplified by a circuit before passing through an Arduino, which activates the motor
                            and induces a contraction within the prosthetic.
                        </div>
                        <br></br>
                        <b>Language:&nbsp;</b>Arduino
                        <br></br>
                        <b>Award:&nbsp;</b>Best Hardware Hack & Wolfram Award (Hack@Brown 2021)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Cat Nguyen, Daniel Chan
                        <div className="actionsContainer">
                            <a href="https://www.youtube.com/watch?v=4iIKbNMxO1Q" target="_blank" rel="noreferrer">
                                <button className="demoBtn">
                                    Video Demo &nbsp;
                                    <FontAwesomeIcon icon={faUpRightFromSquare} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                        <a href="https://devpost.com/software/remote-myoelectric-prosthetics" target="_blank" rel="noreferrer">
                            <button className="codeBtn">
                                Devpost &nbsp;
                                <FontAwesomeIcon icon={faGithub} color="white"></FontAwesomeIcon>
                            </button>
                        </a>
                        <button
                            className="cancelBtn"
                            onClick={() => setIsOpenMyo(false)}
                        >
                            Close
                        </button>
                        </div>
                    </Modal>

                    <div className="grid-item">
                        <img src={RMS_Logo} alt="RMS" onClick={() => setIsOpenRMS(true)}></img>
                        <div>Perhuman</div>
                    </div>
                    <Modal open={isOpenRMS}
                        onClose={() => setIsOpenRMS(false)}
                        center
                        classNames={{
                            overlay: 'customOverlay',
                            modal: 'customModal',
                        }}>
                        <h1 className="heading">Perhuman</h1>
                        <img src={RMS_Desc} alt="Project"></img>
                        <br></br>
                        <br></br>
                        <div>
                            Perhuman is an Amazon Alexa module that helps families and peers
                            provide better verbal feedback to those suffering from mental illness.
                            Inspired by interactive, choose-your-own-adventure stories,
                            PerHuman provides real life situations to users in which someone might need verbal support.
                            After a user responds to Alexa, PerHuman analyzes the users’ replies for negative keywords and
                            phrases and offer guidance for a better response.
                        </div>
                        <br></br>
                        <br></br>
                        <b>Language:&nbsp;</b>Javascript
                        <br></br>
                        <b>Award:&nbsp;</b>Best Tech Prize (HackHealth 2019)
                        <br></br>
                        <b>Team:&nbsp;</b>Soryan Kumar, Daniel Chan, May Gao, Jenny He, Michelle Zhu
                        <div className="actionsContainer">
                            <a href="https://www.browndailyherald.com/2019/09/16/hack-health-event-spotlights-mental-health-fosters-student-innovation/" target="_blank" rel="noreferrer">
                                <button className="codeBtn">
                                    Article &nbsp;
                                    <FontAwesomeIcon icon={faBook} color="white"></FontAwesomeIcon>
                                </button>
                            </a>
                            <button
                                className="cancelBtn"
                                onClick={() => setIsOpenRMS(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default Projects;
